<template>
  <div>
    <el-card>
      <el-form inline>
        <!-- <el-form-item label="线索标题:">
          <el-input v-model="form.title" size="small" />
        </el-form-item> -->
        <el-form-item label="用户昵称:">
          <el-input v-model="form.submitName" size="small" />
        </el-form-item>
        <el-form-item label="提交日期:">
          <el-date-picker size="small" />至
          <el-date-picker size="small" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getVeteranClue">查 询</el-button>
          <el-button size="small" @click="resetForm">重 置</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="clueList" style="width: 100%;padding: 8px 0 ! important;" :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}">
        <el-table-column label="用户昵称" prop="nickName" />
        <!-- <el-table-column label="标题" prop="title" /> -->
        <el-table-column label="提交时间">
          <template slot-scope="{row}">
            <span>{{ row.submitTime | F_formatDate() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="老兵姓名" prop="name" />
        <el-table-column label="状态" prop="veteranStatus" />
        <el-table-column label="提交人姓名" prop="submitName" />
        <el-table-column label="提交人电话" prop="submitPhone" />
        <el-table-column label="提交人邮箱" prop="submitEmail" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="openDetail(row.id)">详情</el-button>
            <el-button type="text" @click="open(row.id)">已处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size" :total="total" layout="total, sizes, prev, pager, next, jumper" style="padding:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </el-card>
    <!-- 详情 -->
    <el-dialog :visible.sync="detailVisible" title="详情" width="800px">
      <el-form size="mini" label-width="100px" label-position="left">
        <el-form-item label="老兵姓名:">
          <span>{{ clueInfo.name }}</span>
        </el-form-item>
        <el-form-item label="籍贯:">
          <span>{{ clueInfo.hometown }}</span>
        </el-form-item>
        <el-form-item label="出生年月:">
          <span>{{ clueInfo.bornDate | F_formatDate }}</span>
        </el-form-item>
        <el-form-item label="参军时间:">
          <span>{{ clueInfo.enlistDate | F_formatDate }}</span>
        </el-form-item>
        <el-form-item label="状态:">
          <span>{{ clueInfo.veteranStatus }}</span>
        </el-form-item>
        <el-form-item label="和提交人关系:">
          <span>{{ clueInfo.relation }}</span>
        </el-form-item>
        <el-form-item label="提交人姓名:">
          <span>{{ clueInfo.submitName }}</span>
        </el-form-item>
        <el-form-item label="提交人电话:">
          <span>{{ clueInfo.submitPhone }}</span>
        </el-form-item>
        <el-form-item label="提交人邮箱:">
          <span>{{ clueInfo.submitEmail }}</span>
        </el-form-item>
        <el-form-item label="补充描述:">
          <div>{{ clueInfo.content }}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  veteranClueList,
  veteranClueInfo,
  deleteVeteranClue,
} from "@/utils/api.js";
export default {
  data() {
    return {
      dataListLoading: true,
      form: {},
      clueList: [],
      detailVisible: false,
      current: 1,
      size: 10,
      total: null,
      clueInfo: {},
    };
  },
  mounted() {
    this.getClueList();
  },
  methods: {
    openDetail(id) {
      this.detailVisible = true;
      this.getClueInfo(id);
    },
    open(id) {
      this.$confirm("是否确认已处理?", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteVeteranClue(id).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "已处理!",
              });
              this.getClueList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 获取线索详情
    async getClueInfo(id) {
      const res = await veteranClueInfo(id);
      if (res.code === 200) {
        this.clueInfo = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    // 获取老兵线索列表
    async getClueList() {
      this.dataListLoading = true;
      const res = await veteranClueList(this.form, this.current, this.size);
      this.dataListLoading = false;
      if (res.code === 200) {
        console.log(res);
        this.clueList = res.data.records;
        this.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    },
    // 查询
    getVeteranClue() {
      this.getClueList();
    },
    // 重置
    resetForm() {
      this.form = {};
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val;
      this.current = 1;
      this.getClueList();
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val;
      this.getClueList();
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: center;
}
</style>
